.react-flow__node-buttonNode {
    font-size: 12px;
    background: #eee;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;

}

.closeButton {
    float : top right;
    color: red;
    /* position: absolute; */
    padding: 1px 2px;
    cursor: pointer;

}

